exports.components = {
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-purpose-category-page-js": () => import("./../../../src/templates/purposeCategoryPage.js" /* webpackChunkName: "component---src-templates-purpose-category-page-js" */),
  "component---src-templates-purpose-page-js": () => import("./../../../src/templates/purposePage.js" /* webpackChunkName: "component---src-templates-purpose-page-js" */),
  "component---src-templates-shared-page-js": () => import("./../../../src/templates/sharedPage.js" /* webpackChunkName: "component---src-templates-shared-page-js" */),
  "component---src-templates-type-category-page-js": () => import("./../../../src/templates/typeCategoryPage.js" /* webpackChunkName: "component---src-templates-type-category-page-js" */),
  "component---src-templates-type-page-js": () => import("./../../../src/templates/typePage.js" /* webpackChunkName: "component---src-templates-type-page-js" */),
  "component---src-templates-type-purpose-category-page-js": () => import("./../../../src/templates/typePurposeCategoryPage.js" /* webpackChunkName: "component---src-templates-type-purpose-category-page-js" */),
  "component---src-templates-type-purpose-page-js": () => import("./../../../src/templates/typePurposePage.js" /* webpackChunkName: "component---src-templates-type-purpose-page-js" */)
}

